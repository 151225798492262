@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Roboto:wght@100;300;400;500;700;900&display=swap');
.navbar{
    /* background-color: aqua; */
    width: 100%;
    display: flex;
    justify-content:space-between ;
    align-items: center;
    height: 70px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.navbar > img{
    height: 80px;
    width: auto;
}   


.logo{
    font-family: 'Pacifico', cursive;
    font-size: 30px;
    margin-left: 50px;
}

.nav-elements{
    margin-right: 50px;
    /* background-color: blue; */
    height: 100%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'Roboto', sans-serif;
    font-size: 19;
}