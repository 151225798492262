.footer{
    height: 130px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
   
}

.footerinner{
    height: 100%;
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.f1{
    /* background-color: antiquewhite; */
    width: 180px;
    height: 70% ;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}


.f2{
    width: 100px;
    height: 70% ;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.f2 > span{
    font-size: 20px;
}

.socicons{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.f1 > span{
    font-size: 20px;
}